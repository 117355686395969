@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
// @include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-button-group;
// @include foundation-callout;
// @include foundation-close-button;
// @include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-drilldown-menu;
// @include foundation-dropdown;
// @include foundation-dropdown-menu;
// @include foundation-flex-video;
// @include foundation-label;
// @include foundation-media-object;
// @include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
// @include foundation-slider;
// @include foundation-sticky;
// @include foundation-reveal;
// @include foundation-switch;
@include foundation-table;
// @include foundation-tabs;
// @include foundation-thumbnail;
// @include foundation-title-bar;
// @include foundation-tooltip;
// @include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-flex-classes;
@include motion-ui-transitions;
@include motion-ui-animations;

// Additional Foundation Settings
a {
	transition: color .26s;
}

// 11. Button
button, .button {
	border: 2px solid $green;
	transition: color .26s, background-color .26s;
	&:hover {
		color: $white;
	}
}

// Components
@import 'components/slick';
@import 'components/slick-theme';
@import 'components/font-awesome';

// Custom Styles

// Main Content
.main {
	min-height: 100vh;
	.valign {
		display: table;
		width: 100%;
		min-height: 100vh;
		.middle {
			display: table-cell;
			vertical-align: middle;
			width: 100%;
			min-height: 100vh;
		}
	}
}

// Helpers

// Vertical Align
.verticalalign {
	display: table;
	width: 100%;
	height: 100%;
	.middle {
		display: table-cell;
		vertical-align: middle;
		width: 100%;
		height: 100%;
	}
}

// Margins
.margin {
	&.no {
		margin: 0 !important;
	}
	&.top {
		margin-top: 287px;
	}
}

// Lists
.list {
	&.inline {
		li {
			display: inline;
			padding-right: 8px;
			&:last-child {
				padding-right: 0;
			}
		}
	}
}

// Editorial
.editorial {
	margin: 287px 0;
}

// Panel
.panel {
	position: relative;
	z-index: 1000;
	padding: 68px 0;
	&.paper {
		position: relative;
		z-index: 1000;
		background-image: url(../img/paper.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}
	&.green {
		position: relative;
		z-index: 2000;
		background-color: $green;
		* {
			color: $white;
		}
		a {
			transition: color 0.26s;
			&:hover {
				color: $yellow;
			}
		}
		hr {
			border-bottom: 1px solid rgba($white, 0.26);
		}
		button, .button {
			border-color: $white;
			transition: color .26s, background-color .26s;
			&:hover {
				color: $green;
				background-color: $white;
			}
		}
		.border {
			position: absolute;
			bottom: -16px;
			right: 0;
			left: 0;
			height: 16px;
			background-image: url(../img/green-border.png);
			background-repeat: repeat-x;
			background-position: top center;
		}
	}
}

// Header
.header {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 3000;
	background: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0) 26%, rgba(0, 0, 0, 0.26) 100%);
	background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0) 26%, rgba(0, 0, 0, 0.26) 100%);
	background: linear-gradient(to top, rgba(0, 0, 0, 0) 26%, rgba(0, 0, 0, 0.26) 100%);
	.search {
		display: none;
		padding: 26px 0;
		background-color: $white;
		form {
			display: inline-block;
			width: 42%;
			input[type="search"] {
				font-size: 16px;
				line-height: 24px;
				height: auto;
				padding: 8px 16px;
				margin-bottom: 0 !important;
				&::-webkit-input-placeholder {
					line-height: inherit;
				}
				&::-moz-placeholder {
					line-height: inherit;
				}
				&:-ms-input-placeholder {
					line-height: inherit;
				}
				&:-moz-placeholder {
					line-height: inherit;
				}
			}
		}
	}
	.logo {
		float: left;
		img {
			margin-top: 26px;
			transform: rotate(-2deg);
			@include breakpoint(medium down) {
				height: 130px;
			}
			@include breakpoint(large) {
				height: 177px;
			}
		}
	}
	.meta {
		ul {
			margin: 0;
			list-style: none !important;
			&.menu {
				@include breakpoint(small only) {
					display: none;
				}
				@include breakpoint(medium only) {
					display: none;
				}
				float: right;
				margin-top: 42px;
				li {
					display: inline-block;
					margin-right: 16px;
					a {
						font-family: 'Roboto Condensed', sans-serif;
						color: $white;
						font-size: 22px;
						font-weight: 700;
						text-transform: uppercase;
					}
					&:last-child {
						margin-right: 0;
					}
					&.current-menu-item, &:hover {
						a {
							color: $dark-gray !important;
						}
					}
					i {
						font-size: 24px;
						color: $white;
						cursor: pointer;
						&:hover {
							color: $dark-gray;
						}
					}
				}
			}
			&.mobile {
				@include breakpoint(large) {
					display: none;
				}
				float: right;
				margin-top: 68px;
				li {
					display: inline-block;
					margin-right: 26px;
					&:last-child {
						margin-right: 0;
					}
					i {
						color: $white;
						font-size: 36px;
						cursor: pointer;
					}
				}
			}
		}
	}
}

// Theme with Paper Background
.paper {
	background-image: url(../img/paper.jpg);
	background-repeat: repeat-y;
	background-size: 100% auto;
	/*.header {
		.menu {
			ul {
				li {
					a, i {
						color: $dark-gray;
					}
				}
			}
		}
	}*/
}

// Hero Slider
.herowrapper {
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	.heroslider {
		position: relative;
		visibility: hidden;
		margin-bottom: 0 !important;
		&.slick-initialized {
			visibility: visible;
		}
		.heroslide {
			position: relative;
			min-height: 768px;
			max-height: 1024px;
			height: 52vw;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
			h1, h2, h3, h4, h5, h6 {
				position: relative;
				span {
					position: relative;
					z-index: 2000;
					background-color: $yellow;
					color: $dark-gray;
					padding: 8px 0;
				}
			}
			p {
				display: inline;
				padding: 8px 0;
				background-color: $white;
			}
			h1, h2, h3, h4, h5, h6, p, a {
				color: $dark-gray !important;
			}
			.middle {
				@include breakpoint(small) {
					width: 90%;
					padding: 5%;
				}
				@include breakpoint(medium) {
					width: 45%;
					padding: 5% 5% 0 50%;
				}
				@include breakpoint(large) {
					width: 35%;
					padding: 5% 15% 0 50%;
				}
			}
		}
		.slick-dots {
			bottom: 86px;
			li {
				button {
					&:before {
						font-size: 16px;
						color: $white;
					}
				}
			}
		}
	}
	.border {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 68px;
		background-image: url(../img/heroslider-border.png);
		background-repeat: x-repeat;
		background-position: bottom center;
	}
}

// Portraitslider
.portraitslider {
	position: relative;
	visibility: hidden;
	@include breakpoint(small) {
		margin-bottom: 68px !important;
	}
	@include breakpoint(medium) {
		margin-bottom: 0 !important;
	}
	&.slick-initialized {
		visibility: visible;
	}
	.portrait {
		position: relative;
		text-align: center;
		outline: none;
		img {
			position: relative;
			z-index: 1000;
			display: inline;
			border-radius: 50%;
			border: 16px solid rgba($white, 0.42);
		}
	}
	.slick-arrow {
		z-index: 2000;
		&:before {
			font-family: 'FontAwesome';
			color: $green;
		}
		&.slick-prev {
			left: 16%;
			&:before {
				content: '\f053';
			}
		}
		&.slick-next {
			right: 16%;
			&:before {
				content: '\f054';
			}
		}
	}
	.slick-dots {
		bottom: -16px;
		li {
			button {
				&:before {
					font-size: 16px;
					color: $green;
				}
			}
		}
	}
}

// The Events Calendar Pro (Wordpress Plugin)
.tribe-mini-calendar-grid-wrapper {
	border-top: 1px solid rgba($white, .26) !important;
	margin: 16px 0 -16px 0 !important;
	.tribe-mini-calendar {
		thead, tbody, tfoot {
			border: 1px $green transparent !important;
			background-color: transparent !important;
			tr {
				background-color: transparent !important;
				td, th {
					border: 1px solid $green !important;
					// height: 68px !important;
					span, a {
						color: #000 !important;
						padding: 26px 16px !important;
					}
				}
			}
		}
		thead {
			tr {
				td {
					padding: 0 !important;
					a {
						padding: 0 !important;
					}
				}
				th {
					padding: 16px !important;
				}
			}
		}
		.tribe-mini-calendar-dayofweek {
			background-color: transparent !important;
		}
		.tribe-events-thismonth, .tribe-events-past {
			background-color: $white !important;
			opacity: 1 !important;
		}
		.tribe-events-othermonth {
			background-color: rgba($white, 0.16) !important;
			span {
				color: $white !important;
			}
		}
		.tribe-events-has-events {
			background-color: $yellow !important;
			a {
				transition: background-color .26s;
			}
			&:hover {
				a {
					background-color: $dark-yellow !important;
				}
			}
			div[id*='daynum-'] a:before {
				content: normal !important;
			}
		}
		.tribe-mini-calendar-nav {
			tr {
				td {
					border-bottom: 1px solid rgba($white, .16) !important;
					* {
						font-family: 'Roboto Slab' !important;
						font-size: 34px !important;
						color: $white !important;
						text-transform: none !important;
					}
				}
			}
		}
	}
}
.tribe-mini-calendar-list-wrapper {
	background-image: url(../img/paper-breakaway.jpg);
	background-repeat: no-repeat;
	background-position: top center;
	background-color: $white;
	background-size: 100% auto;
	padding: 42px;
	* {
		color: $dark-gray !important;
	}
	.type-tribe_events {
		margin: 0 0 16px 0 !important;
		padding: 0 !important;
		.tribe-mini-calendar-event {
			margin: 0 !important;
			padding: 0 !important;
			.list-info {
				margin: 0 !important;
				.tribe-events-title {
					a {
						&:hover {
							text-decoration: underline !important;
						}
					}
				}
			}
		}
		&:last-of-type {
			margin: 0 !important;
		}
	}
	.tribe-mini-calendar-event {
		border-bottom: none !important;
	}
	.list-date {
		display: none !important;
	}
	.list-info {
		.tribe-events-title, .tribe-events-duration {
			font-size: 16px !important;
		}
	}
}

/* Events
.events {
	.meta {
		.current, .controller {
			display: inline-block;
			// border: 1px solid #000;
			* {
				margin: 0;
				line-height: 68px;
			}
		}
		.current {}
		.controller {
			float: right;
			ul {
				list-style: none;
				li {
					display: inline;
					padding: 0 8px;
					a {
						font-size: 26px;
					}
				}
			}
		}
	}
	.calendar {
		table {
			border-spacing: 10px !important;
			margin-bottom: 0;
			tbody {
				background-color: transparent;
				border: none;
				tr {
					background-color: transparent;
					th {
						border-top: 1px solid rgba($white, 0.26);
					}
					th, td {
						text-align: center;
						background-color: transparent;
						padding: 26px 0;
						vertical-align: middle;
					}
					td {
						background-color: $white;
						color: $dark-gray;
						border: 1px solid $green;
						&.disabled {
							opacity: 0.26;
						}
						&.occupied {
							background-color: $yellow;
							cursor: pointer;
							transition: background-color .26s;
							&:hover {
								background-color: scale-color($yellow, $lightness: -26%);
							}
						}
						&.today {
							background-color: $dark-gray !important;
							color: $white !important;
						}
					}
				}
			}
		}
	}
	.upcoming {
		background-image: url(../img/paper-breakaway.jpg);
		background-repeat: no-repeat;
		background-position: top center;
		background-color: $white;
		background-size: 100% auto;
		padding: 42px;
		* {
			color: $dark-gray;
		}
		small {
			display: block;
			margin-bottom: 16px;
		}
		table {
			// border: 1px solid #000;
			margin: 0;
			tbody {
				padding: 42px;
				background-color: transparent;
				border: none;
				tr {
					background-color: transparent;
					th, td {
						font-family: 'Roboto Slab', serif;
						font-weight: 700;
						text-align: left;
						vertical-align: top;
						background-color: transparent;
						padding: 0 26px 8px 0;
					}
				}
			}
		}
	}
}*/

// News
.news {
	position: relative;
	h1, h2, h3 {
		@include breakpoint(small) {
			font-size: 28px;
			margin-bottom: 0;
		}
		@include breakpoint(medium) {
			font-size: 34px;
			margin-bottom: 0;
		}
	}
	.preview, .fullview {
		position: relative;
		margin: 287px 0;
		.row, .columns {
			position: relative;
		}
	}
	.preview {
		h1, h2, h3, h4, h5, h6 {
			margin-bottom: 42px;
		}
		small {
			a {
				color: inherit;
			}
		}
		hr {
			display: block;
			position: absolute;
			left: 13px;
			right: 13px;
			bottom: 0;
			overflow: hidden;
		}
		.filter {
			display: block;
			list-style: none;
			margin: 0;
			// margin-bottom: 68px;
			li {
				display: inline-block;
				margin-right: 16px;
				a {
					display: inline-block;
					padding: 8px 16px;
					background-color: $green;
					border-radius: 3px;
					color: $white;
				}
			}
		}
	}
	.fullview {
		h1, h2, h3 {
			color: $green;
			margin-bottom: 42px;
		}
		small {
			display: block;
			margin-bottom: 26px;
			a {
				color: inherit;
			}
		}
	}
}

// Footer
.footer {
	position: relative;
	z-index: 3000;
	padding: 110px 0 84px 0;
	background-color: $dark-gray;
	h1, h2, h3, h4, h5, h6, p {
		color: $white;
	}
	h1, h2, h3, h4, h5, h6 {
		font-size: 20px;
	}
	p {
		margin-bottom: 26px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	ul {
		list-style: none;
		margin: 0;
		@include breakpoint(small) {
			margin-bottom: 26px;
		}
	}
	.border {
		position: absolute;
		top: -16px;
		right: 0;
		left: 0;
		height: 16px;
		background-image: url(../img/footer-border.png);
		background-repeat: repeat-x;
		background-position: top center;
	}
	.legal {
		li {
			display: inline;
		}
	}
}

// Overlay
.overlay {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 9000;
	background-color: rgba($green, .86);
	overflow: scroll;
	text-align: center;
	ul {
		list-style: none;
		margin: 0;
		margin-bottom: 42px;
		text-align: center;
		li {
			a {
				font-family: 'Roboto Condensed', sans-serif;
				font-size: 26px;
				line-height: 42px;
				font-weight: 700;
				text-transform: uppercase;
			}
		}
	}
	small, a {
		color: $white;
	}
	.valign {
		display: table;
		padding: 16vw;
		width: 100vw;
		height: 100vh;
		.middle {
			display: table-cell;
			vertical-align: middle;
			width: 100%;
			height: 100%;
		}
	}
	.close {
		margin-bottom: 42px;
		small {
			text-transform: uppercase;
			cursor: pointer;
		}
	}
}
